@font-face {
    font-family: 'Hedvig Letters Serif';
    src: url('/client/src/assets/Fonts/HedvigLettersSerif24pt-Regular.otf') format('opentype'), 
  }
  
  @font-face {
    font-family: 'Amiamie';
    src: url('/client/src/assets/Fonts/Amiamie-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'BD';
    src: url('/client/src/assets/Fonts/BDLifelessGrotesk-Light.otf') format('opentype'),
  }
  
  h1{
    font-family: BD;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px; 
  }
  
  h2{
    font-family: Amiamie;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; 
  }
  
  h3{
    font-family: BD;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  h4{
    font-family: Amiamie;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
    text-transform: capitalize;
  }
  
  h5{
    font-family: BD;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; 
  }
  
  p{
    font-family: BD;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
  }