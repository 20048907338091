body, html {
    margin: 0;
    padding: 0;
  }
  
  header {
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #FFFBF2;
    overflow: hidden;
    align-items: center;
  }
  
  header h2, header h3 {
    position: relative;
    z-index: 1;
    color: #092115;
  }
  
  header p{
    position: relative;
    z-index: 1; 
    color: #092115;
    max-width: 493px;
  }
  
  header h1 {
    position: relative;
    z-index: 1; 
    color: #092115;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 600px;
    white-space: nowrap; /* Empêche le texte de sauter à la ligne */
  }
  
  .rectangle {
    width: 2px;
    height: 60px;
    flex-shrink: 0;
    background-color: #092115;
    z-index: 1;
    display: block;
  }
  
  header .align-1 {
    flex-direction: column;
    gap: 10px;
    max-width: 1200px;
    padding-bottom: 70px;
    padding-left: 13%;
  }
  
  header .align-2 {
    display: inline-flex;
    align-items: center;
    gap: 25px;
  }
  header .align-1 h2{
    margin-bottom: 150px;
  }
  header .align-1 .align-3 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  header .align-1 .align-2 {
    display: flex;
  }
  header .align-1 .align-3{
    margin-top: 30px;
  }
  
  .btn-header {
    width: max-content;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 5;
    padding-bottom: 5px;
    height: 34px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #092115; 
    display: flex;  
    justify-content: center;
    align-items: center;
    transition: background-color 0.6s ease, color 0.6s ease; 
  }
  
  .btn-header:hover p{
    transition: background-color 0.6s ease, color 0.6s ease;
  }
  
  .btn-red:hover {
    background-color: #DEFF00;
    border: 1px solid #DEFF00;  
  }
  
  .btn-violet:hover {
    background-color: #DEFF00;
    border: 1px solid #DEFF00;  
  }
  
  .btn-orange:hover {
    background-color: #DEFF00;
    border: 1px solid #DEFF00;  
  }
  
  .btn-green:hover {
    background-color: #DEFF00;
    border: 1px solid #DEFF00;  
  }
  
  .btn-blue:hover {
    background-color: #DEFF00;
    border: 1px solid #DEFF00;  
  }
  
  /* Pour les écrans jusqu'à 475px */
  @media screen and (max-width: 479px) {
    header .align-1 {
      max-width: 70%;
      padding-top: 50px;
    }
    header .align-1 .align-2 h3{
      display: none;
    }
    .rectangle {
      display: none;
    }
  }
  
  @media screen and (min-width: 480px) and (max-width: 1023px) {
    header .align-1 {
      max-width: 70%;
      padding-top: 180px;
      padding-bottom: 70px;
    }
  }
  @media screen and (min-width: 580px) {
    header .align-1 {
      padding-top: 130px;
      padding-bottom: 70px;
    }
  }
  @media screen and (min-width: 680px) {
    header .align-1 {
      padding-top: 10px;
      padding-bottom: 70px;
    }
  }
  @media screen and (min-width: 780px) {
    header .align-1 {
      padding-top: 50px;
      padding-bottom: 70px;
    }
  }
  @media screen and (min-width: 880px) {
    header .align-1 {
      padding-top: 50px;
      padding-bottom: 70px;
    }
  }
  @media screen and (min-width: 980px) {
    header .align-1 {
      padding-top: 50px;
      padding-bottom: 70px;
    }
  }
  
  @media screen and (min-width: 1023px) {
    header .align-1 {
      max-width: 70%;
      padding-bottom: 70px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    header .align-1 {
      max-width: 80%;
      padding-bottom: 70px;
    }
  }

  @media screen and (min-width: 1500px) {
    header {
      height: 60vh;
    }
    header .align-1 {
      max-width: 1200px;
      padding-bottom: 70px;
      padding-left: 0;
      margin: auto;
      margin-right: 1240px;
    }
  }
  