/* Styles de base */
.navbar-background {
  background-color: #FFFBF2;
  padding: 25px 0;
  position: fixed; /* Rendre la barre de navigation fixe */
  top: 0; /* Fixer la barre de navigation en haut de la page */
  width: 100%;
  border-bottom: 1px solid #092115;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: top 0.6s ease;
  z-index: 1000;
}

.navbar-brand {
  /* Pas besoin de changement */
}

.navbar-visible {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 1000; /* Assurez-vous que la barre de navigation est au-dessus de tout le reste */
}

.navbar-hidden {
  position: fixed;
  top: -100px; /* Ou une valeur appropriée pour masquer complètement la barre de navigation */
  left: 0;
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 1000;
}


.link-group {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.link-group li {
  margin-right: 50px;
}

.link-group li a{
  color: #092115; /* Couleur du texte noir */
  text-decoration: none; /* Supprimer la soulignement des liens */
}

.link-group li:last-child {
  margin-right: 0;
}

.navbar-logo {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navbar-logo li {
  margin-left: 20px;
}

.navbar-logo li:first-child {
  margin-left: auto;
}

.navbar-logo li:last-child {
  margin-right: 0;
}

/* Ajoutez ces styles pour les carrés autour des liens */
/* Ajoutez ces styles pour les liens qui ressemblent à des boutons carrés */
.dropdown-menu ul li {
  margin-bottom: -1px;
  text-align: center;
}

.dropdown-menu a {
  display: inline-block; /* Pour permettre la largeur et la hauteur */
  padding: 30px;
  width: 700px; /* Ajoutez un espacement à l'intérieur du bouton */
  border: solid 1px #092115;
  color: #092115; /* Couleur du texte */
  text-decoration: none;
  transition: background-color 0.3s ease; /* Transition de couleur fluide */
}

.dropdown-menu a:hover {
  background-color: red; /* Changez la couleur de fond au survol */
  border: solid 1px #FFFBF2;
  transition: background-color 0.3s ease;
  color: #FFFBF2;
}



/* Styles de base */
.dropdown-wrapper {
  position: relative;
}

.dropdown-button {
  cursor: pointer;
  display: block;
  transition: transform 0.3s ease; /* Ajoutez une transition fluide à la transformation */
}

.dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFBF2; /* Ajoutez une couleur d'arrière-plan semi-transparente */
  z-index: 999; /* Assurez-vous que l'overlay est au-dessus de tout le contenu */
  display: none; /* Cachez initialement l'overlay */
  transition: transform 0.3s ease;
}

.dropdown-menu.open {
  display: block; /* Affichez l'overlay lorsque le menu est ouvert */
}

.dropdown-menu ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  padding: 0;
}


.dropdown-menu a {
  text-decoration: none;
}

.close-button {
  position: absolute;
  top: 25px;
  right: 15%;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.dropdown-language {
  position: relative;
  display: flex;
  align-items: center;
}
.dropdown-language span {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dropdown-language-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-language.active .dropdown-language-content {
  display: block;
}

.dropdown-language-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-language-content a:hover {
  background-color: #f1f1f1;
}

.chevron{
  height: 16px;
  width: 16px;
  rotate: 90deg;
}




/* Media query pour les petits appareils (téléphones en mode portrait) */
@media screen and (max-width: 479px) {
  .navbar-container {
    max-width: 70%;
  }
  .navbar-toggle {
    display: block; /* Afficher le bouton de menu pour les petits appareils */
  }
  .link-group {
    display: none; /* Masquer les liens de navigation pour les petits appareils */
  }
  .dropdown-button {
    display: block; /* Afficher le bouton uniquement sur les écrans de petite taille */
  }
  /* Ajoutez d'autres styles spécifiques pour les petits appareils ici */
}

/* Media query pour les tablettes en mode paysage et les appareils de taille moyenne */
@media screen and (min-width: 480px) and (max-width: 1023px) {
  .navbar-container {
    max-width: 80%;
  }
  .dropdown-button {
    display: none; /* Afficher le bouton uniquement sur les écrans de petite taille */
  }
  /* Ajoutez d'autres styles spécifiques pour les tablettes et les appareils de taille moyenne ici */
}

/* Media query pour les grands écrans et les appareils de bureau */
@media screen and (min-width: 1024px) {
  .navbar-container {
    max-width: 80%;
  }

  .dropdown-button {
    display: none; /* Afficher le bouton uniquement sur les écrans de petite taille */
  }

  /* Ajoutez d'autres styles spécifiques pour les grands écrans et les appareils de bureau ici */
}

/* Media query pour les appareils de bureau de grande taille */
@media screen and (min-width: 1200px) {
  .navbar-container {
    max-width: 1200px;
  }
  .dropdown-button {
    display: none; /* Afficher le bouton uniquement sur les écrans de petite taille */
  }

  /* Ajoutez d'autres styles spécifiques pour les appareils de bureau de grande taille ici */
}
