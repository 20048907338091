.section-6-landing{
    max-width: 1200px;
  }
  
  .section-6-landing .align-2 h2{
    color: #092115;
    margin-bottom: 55px;
  }
  
  .section-6-landing .align-2{
    margin-bottom: 150px;
    margin-top: 150px;
    max-width: 1100px;
  }
  
  .section-6-landing .question {
  
    cursor: pointer;
  }
  
  .section-6-landing .question .align-1{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #092115;
  }
  
  .section-6-landing .question h3 {
    color: #092115;
    margin-bottom: 0;
    padding-bottom: 33px;
    padding-top: 33px;
  }
  
  .section-6-landing .toggle-icon {
    transition: transform 0.3s;
    margin-right: 60px;
    width: 24px;
    height: 24px;
  }
  
  .section-6-landing .arrow-icon {
    transition: transform 0.3s;
  }
  
  .section-6-landing .rotated { /* Nouvelle classe pour la rotation */
    transform: rotate(90deg);
  }
  
  .section-6-landing .reponse {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out; 
  }
  
  .section-6-landing .reponse p {
    margin-top: 33px;
  }