body, html {
    margin: 0;
    padding: 0;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  body::-webkit-scrollbar {
    width: 0.0em;
    height: 0.0em;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: transparent; 
  }