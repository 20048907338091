.footer-container {
  display: flex;
  justify-content: center; /* Centrage horizontal */
  background-color: #092115;
  padding: 150px 13% ;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Les éléments seront empilés verticalement */
}

.footer .align-3 {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  gap: 150px; /* Espace entre les colonnes */
  margin-bottom: 95px;
  align-items: center;
}


.footer .align-3 .align-1{
  max-width: 400px;
}

.footer .align-3 .align-1 .logo{
  height: 32px;
}

.footer .align-3 .align-1 p{
  color: #FFFBF2;
  text-align: justify;
  margin-top: 50px;
  margin-bottom: 0;
  display: block;
}

.footer .align-3 .align-2{
  display: flex;
  flex-direction: row;
  width: max-content;
  gap: 125px;
}

.footer .align-3 .align-2 .align-1{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.footer .align-3 .align-2 h5{
  color: #FFFBF2;
  margin-top: 0;
}

.footer .align-3 .align-2 .align-1 a{
  color: #FFFBF2;
  text-decoration: none;
  font-family: Bd;
}

.footer .align-3 .align-icon{
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center; 
  margin-top: 155px;
  gap: 24px;
  height: 24px;
}

.footer .align-3 .align-icon .icon{
  width: 24px;
  height: 24px;
}

.footer .copyrigth{
  display: flex;
  border-top: 1px solid #FFFBF2;
  justify-content: center;
}

.footer .copyrigth a{
  color: #FFFBF2;
  font-family: Bd;
}

.footer .copyrigth p{
  color: #FFFBF2;
  margin-top: 45px;
}

.footer .copyrigth {
  display: flex;
  border-top: 1px solid #FFFBF2;
  justify-content: center;
  padding-top: 20px; /* Ajout de l'espace */
  width: 100%;
}

.footer .copyrigth a {
  color: #FFFBF2;
  font-family: Bd;
}

.footer .copyrigth p {
  color: #FFFBF2;
  margin-top: 20px; /* Réduit l'espace */
}

@media screen and (max-width: 479px) {
  .footer {
    padding: 40px;
  }
  .footer .align-3 {
    max-width: 100%;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
  }
  .footer .align-3 .align-1 p{
    display: none;
  }
  .footer .align-3 .align-1 .logo{
    margin-top: 50px;
  }
  .footer .align-3 .align-2{
    gap: 50px;
    margin-top: 50px;
  }
  .footer .align-3 .align-icon{
    margin-top: 50px;
  }
}

@media screen and (min-width: 480px) and (max-width: 1023px) {
  .footer .align-3 {
    max-width: 80%;
    flex-direction: column;
  }
  .footer .align-3 .align-1 p{
    display: none;
  }
  .footer .align-3 .align-icon{
    margin-top: 20px;
  }
  .footer {
    align-items: center;
  }
}

@media screen and (min-width: 1023px) {
  .footer .align-3 {
    max-width: 80%;
  }
}

@media screen and (min-width: 1200px) {
  .footer .align-3 {
    max-width: 1075px;
    gap: 100px;
  }
}
