.section-2-landing {
  align-items: center;
  height: auto;
  background-color: #DEFF00;
}
.section-2-landing .background-section {
  background-color: transparent;
  padding: 0;
}

.section-2-landing .background-section .align-2 {
  display: flex; /* Add this to make the blocks display in a row */
  justify-content: space-between; /* Add this to put space between the blocks */
  width: 100%; /* Adjust this as needed */
}

.section-2-landing .background-section .align-2 .align-1 {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 15px;
}

.section-2-landing .background-section .align-2 .align-1 a{
  width: auto;
  height: 24px;
  margin-top: 50px;
}

.section-2-landing .background-section .align-2 .align-1 .link{
  display: inline-block;
  width: 205px;
  color: #092115;
  text-decoration: underline #092115;
  gap: 20px;
}

.section-2-landing .background-section .align-2 .block-1 {
  width: 100%; 
  background-image: url('../assets/svg/point.svg');
  background-position: center; /* Move this here */
  background-repeat: repeat;
}


.point {
  width: 2px; /* Largeur du point */
  height: 2px; /* Hauteur du point */
  background-color: #092115; /* Couleur du point */
  border-radius: 50%; /* Pour faire un cercle */
  position: absolute;
}


.section-2-landing .background-section .align-2 .align-1.block-2 {
  background-color: transparent; /* Change this to the color you want for the second block */
  padding: 100px 198px 100px 100px;
  width: 100%;
  max-width: 50%;
}

/* Pour les écrans jusqu'à 475px */
@media screen and (max-width: 479px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }
  .section-2-landing .background-section .align-2 {
    display:block; /* Add this to make the blocks display in a row */
  }
  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 60px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 1023px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }
  .section-2-landing .background-section .align-2 {
    display:block; /* Add this to make the blocks display in a row */
  }
  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 67px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 580px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }

  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 125px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 680px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }

  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 70px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 780px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }

  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 125px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 880px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }

  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 125px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 980px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }
  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 125px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1023px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }
  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 195px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 100%;
  }

  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 125px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1500px) {
  .section-2-landing .background-section .align-2 .align-1 .block-1 {
    width: 0%;
  }

  .section-2-landing .background-section .align-2 .align-1.block-2 {
    padding: 125px;
    width: 100%;
    max-width: 100%;
  }
}
